import React from 'react'
import Allroutes from "./Components/Allroutes"

const App = () => {
  return (
    <div>
      <Allroutes />
    </div>
  )
}

export default App